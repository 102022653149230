.render-choice-content-highlight {

    display:"flex";
    flex-grow: "1";
    width:"100%";

  h5:hover {
    background-color: rgba(32,168,216,0.1);
  }
}

.editor-content-item{
    display:"flex";
    flex-grow: "1";
    width:"100%";
    color: black;
    font-size: "1em";
    margin-top: "25px";
}