
.render-content-highlight {

    display:"flex";
    flex-grow: "1";
    width:"100%";

  h1:hover, h2:hover, h3:hover, h4:hover, p:hover{
    background-color: rgba(32,168,216,0.1);
  }
}

.editor-content-item{
    display:"flex";
    flex-grow: "1";
    width:"100%";
    font-size: "1em";
    margin-top: "25px";
}

