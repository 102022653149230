/// Survey - FORMS

.survey-package-card{
  margin-left: 5px;
  margin-right: 5px;

  label{
    font-size: 0.8em;
    font-weight:300
  }

}

