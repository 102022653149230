
///colors
$white:#fff;
$primary: #20a8d8;
$secondary: #c8ced3;
$danger: #f86c6b;
$info: #63c2d3;
$light: #f0f3f5;
$dark: #2f353a;
$success: #4dbd74;
$warning: #ffc107;
$gray-base: #181b1e;
$gray-100:  #f0f3f5;
$gray-200:  #e4e7ea;
$gray-300:  #c8ced3;
$gray-400:  #acb4bc;
$gray-500:  #8f9ba6;
$gray-600:  #73818f;
$gray-700:  #5c6873;
$gray-800:  #2f353a;
$gray-900:  #23282c;
$black:     #000;


$mainbackground:  #f86c6b;
$mainaccentcolor: #63c2d3;


///fonts
$primaryfont: 'Roboto';
$secondaryfont: 'Raleway';
$defaultfontsize: 14px;

.survey-loader{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}

/// surveywrapper - background color - main background
/// removed 100vh and 75px top margin change from normal css

.survey-wrapper-editor {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: stretch;
  padding-top: 0px;
  padding-bottom: 25px;
  overflow-x: hidden;
  overflow-y: hidden;
  font-family: $primaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
  max-height: 100vh;

  h1{
    font-weight: 600;
    font-size: 2em;
  }
  h2{
    font-weight: 200;
    font-size: 1.5em;
  }
  h3{
    font-weight: 400;
    font-size: 1.25em;
  }
  h4{
    font-weight: 100;
    font-size: 0.8em;
  }
  p {
    font-weight: 400;
  }
}

.survey-editor-canvas-card{
    min-width: 80vw;
    min-height: 60vh;
    max-height: 100vh
  
  }

/// page level props

.survey-page-headings {
  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 15px;
  
  h2{
    font-weight: 200;
    font-size: 1.5em;
  }

  h4{
    font-family: $primaryfont;
    font-size: 0.8em;
    font-weight: 300;
  }
}

.survey-page-card-body{
  min-height: 50px;

}

.survey-page-navigation{
  display: flex;
  font-size: 2em;
  font-size: 500;
}

.survey-page-footer{
  display: flex;
  font-size: .6em;
  font-weight: 200;
  margin-bottom: 25px;
}

/// question props

.survey-question-headings {
  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
  margin-top: 15px;
  margin-bottom: 10px;
  margin-left: 15px;
  
  h2{
    font-weight: 600;
  }

  h4{
    font-family: $primaryfont;
    font-weight: 300;
  }
}

.survey-row-of-cards{
display: flex;
flex-direction: row;
align-items: stretch; 
justify-content: space-evenly; 
flex-wrap: wrap;
}


/// Survey - FORMS

.survey-form-card{
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;

}

.survey-form-card-label{
  font-size: smaller;
  font-weight: 300;
  margin-top: 25px;
  margin-left: 15px;
  margin-right: 15px;

}

.survey-form-card-text{
  margin-left: 15px;
  margin-right: 15px;

}

.survey-form-card-header{

  font-family: $secondaryfont;
  font-size: $defaultfontsize;
  font-weight: 400;
}

.survey-form-card-footer{

  font-family: $primaryfont;
  font-size: 0.75em;
  font-weight: 300;
  background-color: transparent;
}

/// survey CARDS

.survey-choice-card{
  flex-grow: 1;
  margin-left: 5px;
  margin-right: 5px;

}

.survey-choice-card-footer{

  font-family: $primaryfont;
  font-size: .75em;
  font-weight: 300;

}

.survey-feature-list{

  font-family: $primaryfont;
  font-weight: 200;
}

/// survey LIST

.survey-list-table{

  font-size: 1em;
  font-weight: 300;
}

.survey-list-features{

  font-size: 0.75em;
  font-weight: 200;
}

.survey-list-col-header{

  font-size: 0.6em;
  font-weight: 200;
}


/// survey CONTENT

.survey-content-jumbotron2{
  
  h1{
    font-weight: 500;
    font-size: 3em;
    font-family: $secondaryfont;
    line-height: 125%;
  }

  h4{
    font-family: $primaryfont;
    font-size: 1.25em;
    font-weight: 200;
  }

  p{
    font-family: $primaryfont;
    font-size: 0.8em;
    font-weight: 300;
  }

  hr{
    border-top: 1px dotted #8c8b8b;
  }

.editor-survey-pageprop-jumbotron2{

  h1{
    font-weight: 500;
    font-size: 3em;
    font-family: $secondaryfont;
    line-height: 125%;
    color: #f0f5f5
  }

  h4{
    font-family: $primaryfont;
    font-size: 1.25em;
    font-weight: 200;
    color: #f0f3f5
  }

  p{
    font-family: $primaryfont;
    font-size: 0.8em;
    font-weight: 300;
    color: #20a8d8
  }

  hr{
    border-top: 1px dotted #20a8d8;
  }

}



}
